import React from 'react';
import PropTypes from 'prop-types';

class SlideNav extends React.Component {
  static propTypes = {
    img: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
  };

  render() {
    const { img, index } = this.props;
    const slideCounter = 10 > index ? `0${index}` : index;

    return (
      <div
        className="Slider-menu"
        style={{ backgroundImage: `url(${img})` }}
        key={index}
      >
        <span data-index={index - 1} className="Grid__item-count">
          {slideCounter}
        </span>
      </div>
    );
  }
}

export default SlideNav;
