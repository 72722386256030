import React from 'react';
import PropTypes from 'prop-types';

import './styles/Title.css';

class Title extends React.Component {
  static propTypes = {
    el: PropTypes.string,
    className: PropTypes.string.isRequired,
  };

  render() {
    const { className, children } = this.props;
    const Element = this.props.el || 'p';

    return (
      <Element className={className}>
        <span className="Title__underline">{children}</span>
      </Element>
    );
  }
}

export default Title;
