import React from 'react';
import { Link } from 'gatsby-plugin-modal-routing';
import { mapPoints } from './Helpers';
import image from '../images/point.png';
const spots = mapPoints();

function dotsTiming(n) {
  const time = {
    animationDelay: `${n}s`,
  };
  return time;
}

class MapSVG extends React.Component {
  render() {
    return (
      <svg className="Map__svg" viewBox="0 0 1456 1007.19">
        <g className={`Map__group ${spots.nol.id}`} id="Louisiana_Dots">
          <Link
            className={`Map__link ${spots.nol.id}`}
            to={`/modals/map/${spots.nol.link}`}
            asModal
          >
            <g width="40" height="40" transform="translate(812 738)">
              <circle
                style={dotsTiming(2.75)}
                className="point-move"
                r="40"
                fill="#efefef"
              />
            </g>
            <image
              className="map-spot-img"
              x="800"
              y="710"
              xlinkHref={image}
              width="30"
              height="30"
            />
            <g
              id="Group_189"
              className="Map-text"
              transform="translate(585 725)"
            >
              <g id="Rectangle_58">
                <rect className="cls-1" width="213" height="51" />
                <rect className="cls-2" x="1" y="1" width="211" height="49" />
              </g>

              <g id="Group_188">
                <g id="Mississippi_Delta" className="cls-3">
                  <text className="cls-4" transform="translate(20 34)">
                    New Orleans, LA
                  </text>
                </g>
                <path className="cls-10" d="M187.22,20.89,174.56,33.31" />
                <path className="cls-10" d="M188.37,29.16V20.38h-8.78" />
              </g>
            </g>
          </Link>
        </g>

        <g className={`Map__group ${spots.ben.id}`} id="Arkansas_Dots">
          <Link
            className={`Map__link ${spots.ben.id}`}
            to={`/modals/map/${spots.ben.link}`}
            asModal
          >
            <g width="40" height="40" transform="translate(587 338)">
              <circle
                className="point-move"
                r="40"
                fill="#efefef"
                style={dotsTiming(2.5)}
              />
            </g>
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="575"
              y="310"
              width="30"
              height="30"
            />
            <g className="Map-text" transform="translate(320 330)">
              <g
                data-name="Rectangle 58"
                fill="#d10000"
                stroke="#262262"
                stroke-width="2"
              >
                <path stroke="none" d="M0 0h248v51H0z" />
                <path fill="none" d="M1 1h246v49H1z" />
              </g>
              <g data-name="Group 188">
                <text
                  data-name="Northwest Arkansas"
                  transform="translate(20.001 34)"
                  fill="#fff"
                  font-size="27"
                  className="cls-4"
                >
                  Northwest Arkansas
                </text>
                <path
                  data-name="Path 101"
                  d="M222.223 20.887L209.56 33.313"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                />
                <path
                  data-name="Path 102"
                  d="M223.369 29.158v-8.78l-8.779.001"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                />
              </g>
            </g>
          </Link>
        </g>

        <g className={`Map__group ${spots.cha.id}`} id="North_Carolina_Dots">
          <Link
            className={`Map__link ${spots.cha.id}`}
            to={`/modals/map/${spots.cha.link}`}
            asModal
          >
            <g width="40" height="40" transform="translate(1254 343)">
              <circle
                style={dotsTiming(1.25)}
                className="point-move"
                r="40"
                fill="#efefef"
              />
            </g>
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="1242"
              y="315"
              width="30"
              height="30"
            />
            <g className="Map-text" transform="translate(1050 335)">
              <g
                data-name="Rectangle 58"
                fill="#d10000"
                stroke="#262262"
                stroke-width="2"
              >
                <path stroke="none" d="M0 0h184v51H0z" />
                <path fill="none" d="M1 1h182v49H1z" />
              </g>
              <g data-name="Group 188">
                <text
                  transform="translate(20 34)"
                  fill="#fff"
                  font-size="27"
                  className="cls-4"
                >
                  <tspan x="0" y="0">
                    Charlotte, NC
                  </tspan>
                </text>
                <path
                  data-name="Path 101"
                  d="M158.222 20.887l-12.663 12.426"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                />
                <path
                  data-name="Path 102"
                  d="M159.369 29.158v-8.78l-8.779.001"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                />
              </g>
            </g>
          </Link>
        </g>

        <g className={`Map__group ${spots.msd.id}`} id="Delta_Dots">
          <Link
            className={`Map__link ${spots.msd.id}`}
            to={`/modals/map/${spots.msd.link}`}
            asModal
          >
            <g width="40" height="40" transform="translate(752 520)">
              <circle
                style={dotsTiming(1.85)}
                className="point-move"
                r="40"
                fill="#efefef"
              />
            </g>
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="740"
              y="492"
              width="30"
              height="30"
            />
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="755"
              y="461"
              width="30"
              height="30"
            />
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="755"
              y="480"
              width="30"
              height="30"
            />
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="740"
              y="492"
              width="30"
              height="30"
            />
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="769"
              y="482"
              width="30"
              height="30"
            />
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="762"
              y="470"
              width="30"
              height="30"
            />
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="765"
              y="473"
              width="30"
              height="30"
            />
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="729"
              y="545"
              width="30"
              height="30"
            />
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="727"
              y="522"
              width="30"
              height="30"
            />
            <g
              id="Group_189"
              className="Map-text"
              transform="translate(540 492)"
            >
              <g id="Rectangle_58">
                <rect className="cls-1" width="213" height="51" />
                <rect className="cls-2" x="1" y="1" width="211" height="49" />
              </g>

              <g id="Group_188">
                <g id="Mississippi_Delta" className="cls-3">
                  <text className="cls-4" transform="translate(20 34)">
                    Mississippi Delta
                  </text>
                </g>
                <path className="cls-10" d="M187.22,20.89,174.56,33.31" />
                <path className="cls-10" d="M188.37,29.16V20.38h-8.78" />
              </g>
            </g>
          </Link>
        </g>

        <g className={`Map__group ${spots.jac.id}`} id="Jackson_Dots">
          <Link
            className={`Map__link ${spots.jac.id}`}
            to={`/modals/map/${spots.jac.link}`}
            asModal
          >
            <g width="40" height="40" transform="translate(794 573)">
              <circle
                style={dotsTiming(2)}
                className="point-move"
                r="40"
                fill="#efefef"
              />
            </g>
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="782"
              y="545"
              width="30"
              height="30"
            />
            <g className="Map-text" transform="translate(602 565)">
              <g
                data-name="Rectangle 58"
                fill="#d10000"
                stroke="#262262"
                stroke-width="2"
              >
                <path stroke="none" d="M0 0h184v51H0z" />
                <path fill="none" d="M1 1h182v49H1z" />
              </g>
              <g data-name="Group 188">
                <text
                  transform="translate(20 34)"
                  fill="#fff"
                  font-size="27"
                  className="cls-4"
                >
                  <tspan x="0" y="0">
                    Jackson, MS
                  </tspan>
                </text>
                <path
                  data-name="Path 101"
                  d="M158.222 20.887l-12.663 12.426"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                />
                <path
                  data-name="Path 102"
                  d="M159.369 29.158v-8.78l-8.779.001"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                />
              </g>
            </g>
          </Link>
        </g>

        <g className={`Map__group ${spots.bir.id}`} id="Bham_Dots">
          <Link
            className={`Map__link ${spots.mon.id}`}
            to={`/modals/map/${spots.mon.link}`}
            asModal
          >
            <g width="40" height="40" transform="translate(992 563)">
              <circle
                style={dotsTiming(0.5)}
                className="point-move"
                r="40"
                fill="#efefef"
              />
            </g>
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="980"
              y="535"
              width="30"
              height="30"
            />
            <g
              id="Group_189"
              className="Map-text"
              transform="translate(760 565)"
            >
              <g id="Rectangle_58">
                <rect className="cls-1" width="213" height="51" />
                <rect className="cls-2" x="1" y="1" width="211" height="49" />
              </g>

              <g id="Group_188">
                <g id="Mississippi_Delta" className="cls-3">
                  <text className="cls-4" transform="translate(20 34)">
                    Montgomery, AL
                  </text>
                </g>
                <path className="cls-10" d="M187.22,20.89,174.56,33.31" />
                <path className="cls-10" d="M188.37,29.16V20.38h-8.78" />
              </g>
            </g>
          </Link>
        </g>

        <g className={`Map__group ${spots.col.id}`} id="Georgia_Dots">
          <Link
            className={`Map__link ${spots.col.id}`}
            to={`/modals/map/${spots.col.link}`}
            asModal
          >
            <g width="40" height="40" transform="translate(1072 533)">
              <circle
                className="point-move point-move-2"
                r="40"
                fill="#efefef"
              />
            </g>
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="1060"
              y="505"
              width="30"
              height="30"
            />
            <g className="Map-text" transform="translate(880 525)">
              <g
                data-name="Rectangle 58"
                fill="#d10000"
                stroke="#262262"
                stroke-width="2"
              >
                <path stroke="none" d="M0 0h184v51H0z" />
                <path fill="none" d="M1 1h182v49H1z" />
              </g>
              <g data-name="Group 188">
                <text
                  transform="translate(20 34)"
                  fill="#fff"
                  font-size="27"
                  className="cls-4"
                >
                  <tspan x="0" y="0">
                    Columbus, GA
                  </tspan>
                </text>
                <path
                  data-name="Path 101"
                  d="M158.222 20.887l-12.663 12.426"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                />
                <path
                  data-name="Path 102"
                  d="M159.369 29.158v-8.78l-8.779.001"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                />
              </g>
            </g>
          </Link>
        </g>

        <g className={`Map__group ${spots.cApp.id}`} id="Central_App_Dots">
          <Link
            className={`Map__link ${spots.cApp.id}`}
            to={`/modals/map/${spots.cApp.link}`}
            asModal
          >
            <g width="40" height="40" transform="translate(1142 213)">
              <circle
                style={dotsTiming(2.75)}
                className="point-move"
                r="40"
                fill="#efefef"
              />
            </g>
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="1175"
              y="110"
              width="30"
              height="30"
            />
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="1165"
              y="115"
              width="30"
              height="30"
            />
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="1158"
              y="128"
              width="30"
              height="30"
            />
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="1058"
              y="210"
              width="30"
              height="30"
            />
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="1095"
              y="200"
              width="30"
              height="30"
            />
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="1110"
              y="210"
              width="30"
              height="30"
            />
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="1130"
              y="185"
              width="30"
              height="30"
            />
            <g className="Map-text" transform="translate(820 225)">
              <g
                data-name="Rectangle 58"
                fill="#d10000"
                stroke="#262262"
                stroke-width="2"
              >
                <path stroke="none" d="M0 0h235v51H0z" />
                <path fill="none" d="M1 1h233v49H1z" />
              </g>
              <g data-name="Group 188">
                <text
                  data-name="Central Appalachia"
                  transform="translate(22 34)"
                  fill="#fff"
                  font-size="27"
                  className="cls-4"
                >
                  <tspan x="0" y="0">
                    Central Appalachia
                  </tspan>
                </text>
                <path
                  data-name="Path 101"
                  d="M208.887 20.887l-12.663 12.426"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                />
                <path
                  data-name="Path 102"
                  d="M210.368 29.158v-8.78l-8.779.001"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                />
              </g>
            </g>
          </Link>
        </g>

        <g className={`Map__group ${spots.kno.id}`} id="Tennessee_Dots">
          <Link
            className={`Map__link ${spots.kno.id}`}
            to={`/modals/map/${spots.kno.link}`}
            asModal
          >
            <g width="40" height="40" transform="translate(1092 308)">
              <circle
                style={dotsTiming(0.25)}
                className="point-move"
                r="40"
                fill="#efefef"
              />
            </g>
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="1080"
              y="280"
              width="30"
              height="30"
            />
            <g className="Map-text" transform="translate(888 300)">
              <g
                data-name="Rectangle 58"
                fill="#d10000"
                stroke="#262262"
                stroke-width="2"
              >
                <path stroke="none" d="M0 0h184v51H0z" />
                <path fill="none" d="M1 1h182v49H1z" />
              </g>
              <g data-name="Group 188">
                <text
                  transform="translate(20 34)"
                  fill="#fff"
                  font-size="27"
                  className="cls-4"
                >
                  <tspan x="0" y="0">
                    Knoxville, TN
                  </tspan>
                </text>
                <path
                  data-name="Path 101"
                  d="M158.222 20.887l-12.663 12.426"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                />
                <path
                  data-name="Path 102"
                  d="M159.369 29.158v-8.78l-8.779.001"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                />
              </g>
            </g>
          </Link>
        </g>

        <g className={`Map__group ${spots.ric.id}`} id="Virginia_Dots">
          <Link
            className={`Map__link ${spots.ric.id}`}
            to={`/modals/map/${spots.ric.link}`}
            asModal
          >
            <g width="40" height="40" transform="translate(1390 163)">
              <circle
                style={dotsTiming(1.5)}
                className="point-move"
                r="40"
                fill="#efefef"
              />
            </g>
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="1378"
              y="135"
              width="30"
              height="30"
            />
            <g className="Map-text" transform="translate(1185 155)">
              <g
                data-name="Rectangle 58"
                fill="#d10000"
                stroke="#262262"
                stroke-width="2"
              >
                <path stroke="none" d="M0 0h184v51H0z" />
                <path fill="none" d="M1 1h182v49H1z" />
              </g>
              <g data-name="Group 188">
                <text
                  transform="translate(20 34)"
                  fill="#fff"
                  font-size="27"
                  className="cls-4"
                >
                  <tspan x="0" y="0">
                    Richmond, VA
                  </tspan>
                </text>
                <path
                  data-name="Path 101"
                  d="M158.222 20.887l-12.663 12.426"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                />
                <path
                  data-name="Path 102"
                  d="M159.369 29.158v-8.78l-8.779.001"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                />
              </g>
            </g>
          </Link>
        </g>

        <g className={`Map__group ${spots.lou.id}`} id="Lville_Dots">
          <Link
            className={`Map__link ${spots.lou.id}`}
            to={`/modals/map/${spots.lou.link}`}
            asModal
          >
            <g width="40" height="40" transform="translate(983 188)">
              <circle
                style={dotsTiming(2)}
                className="point-move"
                r="40"
                fill="#efefef"
              />
            </g>
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="971"
              y="160"
              width="30"
              height="30"
            />
            <g className="Map-text" transform="translate(785 185)">
              <g
                data-name="Rectangle 58"
                fill="#d10000"
                stroke="#262262"
                stroke-width="2"
              >
                <path stroke="none" d="M0 0h184v51H0z" />
                <path fill="none" d="M1 1h182v49H1z" />
              </g>
              <g data-name="Group 188">
                <text
                  transform="translate(20 34)"
                  fill="#fff"
                  font-size="27"
                  className="cls-4"
                >
                  <tspan x="0" y="0">
                    Louisville, KY
                  </tspan>
                </text>
                <path
                  data-name="Path 101"
                  d="M158.222 20.887l-12.663 12.426"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                />
                <path
                  data-name="Path 102"
                  d="M159.369 29.158v-8.78l-8.779.001"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                />
              </g>
            </g>
          </Link>
        </g>

        <g className={`Map__group ${spots.hou.id}`} id="Texas_Dots">
          <Link
            className={`Map__link ${spots.hou.id}`}
            to={`/modals/map/${spots.hou.link}`}
            asModal
          >
            <g width="40" height="40" transform="translate(533 748)">
              <circle className="point-move" r="40" fill="#efefef" />
            </g>
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="521"
              y="720"
              width="30"
              height="30"
            />
            <g className="Map-text" transform="translate(335 750)">
              <g
                data-name="Rectangle 58"
                fill="#d10000"
                stroke="#262262"
                stroke-width="2"
              >
                <path stroke="none" d="M0 0h184v51H0z" />
                <path fill="none" d="M1 1h182v49H1z" />
              </g>
              <g data-name="Group 188">
                <text
                  transform="translate(20 34)"
                  fill="#fff"
                  font-size="27"
                  className="cls-4"
                >
                  <tspan x="0" y="0">
                    Houston, TX
                  </tspan>
                </text>
                <path
                  data-name="Path 101"
                  d="M158.222 20.887l-12.663 12.426"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                />
                <path
                  data-name="Path 102"
                  d="M159.369 29.158v-8.78l-8.779.001"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                />
              </g>
            </g>
          </Link>
        </g>

        <g className={`Map__group ${spots.mon.id}`} id="Montg_Dots">
          <Link
            className={`Map__link ${spots.bir.id}`}
            to={`/modals/map/${spots.bir.link}`}
            asModal
          >
            <g width="40" height="40" transform="translate(970 491)">
              <circle
                style={dotsTiming(1)}
                className="point-move"
                r="40"
                fill="#efefef"
              />
            </g>
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="958"
              y="463"
              width="30"
              height="30"
            />

            <g
              id="Group_189"
              className="Map-text"
              transform="translate(740 495)"
            >
              <g id="Rectangle_58">
                <rect className="cls-1" width="213" height="51" />
                <rect className="cls-2" x="1" y="1" width="211" height="49" />
              </g>

              <g id="Group_188">
                <g id="Mississippi_Delta" className="cls-3">
                  <text className="cls-4" transform="translate(20 34)">
                    Birmingham, AL
                  </text>
                </g>
                <path className="cls-10" d="M187.22,20.89,174.56,33.31" />
                <path className="cls-10" d="M188.37,29.16V20.38h-8.78" />
              </g>
            </g>
          </Link>
        </g>

        <g className={`Map__group ${spots.mia.id}`} id="Florida_Dots">
          <Link
            className={`Map__link ${spots.mia.id}`}
            to={`/modals/map/${spots.mia.link}`}
            asModal
          >
            <g width="40" height="40" transform="translate(1372 903)">
              <circle
                style={dotsTiming(0.75)}
                className="point-move"
                r="40"
                fill="#efefef"
              />
            </g>
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="1360"
              y="875"
              width="30"
              height="30"
            />
            <g className="Map-text" transform="translate(1200 890)">
              <g
                data-name="Rectangle 58"
                fill="#d10000"
                stroke="#262262"
                stroke-width="2"
              >
                <path stroke="none" d="M0 0h149v51H0z" />
                <path fill="none" d="M1 1h147v49H1z" />
              </g>
              <g data-name="Group 188">
                <text
                  data-name="Miami, FL"
                  transform="translate(20 34)"
                  fill="#fff"
                  font-size="27"
                  className="cls-4"
                >
                  <tspan x="0" y="0">
                    Miami, FL
                  </tspan>
                </text>
                <path
                  data-name="Path 101"
                  d="M123.222 20.887l-12.663 12.426"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                />
                <path
                  data-name="Path 102"
                  d="M124.368 29.158v-8.78l-8.779.001"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                />
              </g>
            </g>
          </Link>
        </g>

        <g className={`Map__group ${spots.chr.id}`} id="South_Carolina_Dots">
          <Link
            className={`Map__link ${spots.chr.id}`}
            to={`/modals/map/${spots.chr.link}`}
            asModal
          >
            <g width="40" height="40" transform="translate(1307 485)">
              <circle className="point-move" r="40" fill="#efefef" />
            </g>
            <image
              className="map-spot-img"
              xlinkHref={image}
              x="1295"
              y="457"
              width="30"
              height="30"
            />
            <g className="Map-text" transform="translate(1100 490)">
              <g
                data-name="Rectangle 58"
                fill="#d10000"
                stroke="#262262"
                stroke-width="2"
              >
                <path stroke="none" d="M0 0h191v51H0z" />
                <path fill="none" d="M1 1h189v49H1z" />
              </g>
              <g data-name="Group 188">
                <text
                  data-name="Charleston, SC"
                  transform="translate(20 34)"
                  fill="#fff"
                  font-size="27"
                  className="cls-4"
                >
                  <tspan x="0" y="0">
                    Charleston, SC
                  </tspan>
                </text>
                <path
                  data-name="Path 101"
                  d="M166.474 20.887l-12.663 12.426"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                />
                <path
                  data-name="Path 102"
                  d="M166.368 29.158v-8.78l-8.779.001"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                />
              </g>
            </g>
          </Link>
        </g>
      </svg>
    );
  }
}

export default MapSVG;
