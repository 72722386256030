import React from 'react';
import Img from 'gatsby-image';
import Container from './Container';
import Title from './Title';
import Copyright from './Copyright';
import './styles/Footer.css';
import report from '../report/report.pdf';
import { fadeIn } from '../components/Helpers';

class Footer extends React.Component {
  componentDidMount() {
    fadeIn();
  }

  render() {
    const { footerLogo } = this.props;

    return (
      <footer className="Footer">
        <Container className="Grid Grid--3 Grid--3-wide-center">
          <div className="Grid__item">
            <Title el="h5" className="Title--3 Title--c foot-title">
              About Us
            </Title>
            <p>
              We seek to fulfill America’s promise of justice and opportunity
              for all.
              <br />
              <a target="_blank" rel="noopener noreferrer" href={report}>
                Learn more here.
              </a>
            </p>
          </div>

          <div className="Grid__item Grid__item--center">
            <Img
              fixed={footerLogo.childImageSharp.fixed}
              alt="E Pluribus Unum"
            />
            <p className="Tagline">[ Out of many, one ]</p>
            <Title className="Title--6 Title--c Copyright">
              <Copyright /> E Pluribus Unum
            </Title>
          </div>
          <div className="Grid__item Grid__item--right">
            <Title el="h5" className="Title--3 Title--c foot-title">
              Follow Us
            </Title>
            <ul className="Social">
              <li className="Social__network">
                <a
                  className="Social__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/UnumFund/"
                >
                  <svg
                    id="Facebook"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 159 172.72"
                  >
                    <path
                      id="f09a"
                      className="Social__network__icon"
                      d="M114.68,10.74V35.08H100.2q-7.94,0-10.7,3.32t-2.76,10V65.79h27l-3.59,27.3H86.74v70H58.52v-70H35V65.79H58.52V45.69q0-17.16,9.59-26.61T93.66,9.63A150.69,150.69,0,0,1,114.68,10.74Z"
                    />
                    <rect
                      className="cls-2"
                      width="159"
                      height="172.72"
                      fill="transparent"
                    />
                  </svg>
                </a>
              </li>
              <li className="Social__network">
                <a
                  className="Social__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/unumfund"
                >
                  <svg
                    id="Twitter"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 209.48 170.14"
                  >
                    <path
                      className="Social__network__icon"
                      d="M209.477 20.2a89.962 89.962 0 0 1-21.533 22.2q.133 1.861.133 5.582a121.96 121.96 0 0 1-5.051 34.492 124.649 124.649 0 0 1-15.352 33.03 129.84 129.84 0 0 1-24.523 27.979q-14.222 12.162-34.293 19.406a125.5 125.5 0 0 1-42.932 7.244A119.219 119.219 0 0 1 0 150.86a91.887 91.887 0 0 0 10.368.532 84.054 84.054 0 0 0 53.3-18.342 43.022 43.022 0 0 1-40.141-29.773 54.387 54.387 0 0 0 8.108.665 44.415 44.415 0 0 0 11.3-1.462 42.238 42.238 0 0 1-24.656-14.82 41.478 41.478 0 0 1-9.772-27.316v-.532a42.592 42.592 0 0 0 19.406 5.45 42.883 42.883 0 0 1-13.957-15.285 41.831 41.831 0 0 1-5.183-20.47 42.063 42.063 0 0 1 5.848-21.665 121.7 121.7 0 0 0 39.144 31.7 119.625 119.625 0 0 0 49.379 13.225 47.688 47.688 0 0 1-1.063-9.836 41.375 41.375 0 0 1 12.561-30.371 43.034 43.034 0 0 1 61.74 1 84.181 84.181 0 0 0 27.247-10.37 41.682 41.682 0 0 1-18.874 23.659 85.542 85.542 0 0 0 24.722-6.649zm5.848 149.93"
                    />
                  </svg>
                </a>
              </li>
              <li className="Social__network">
                <a
                  className="Social__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/channel/UCzLrAxni49J3uhzWHHAnVLg/featured"
                >
                  <svg
                    id="YouTube"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 31.704 22.467"
                  >
                    <g transform="translate(0.002 56.25)">
                      <path
                        className="Social__network__icon"
                        d="M12.577-41l8.562-4.423L12.577-49.9ZM15.85-56.25q2.972,0,5.741.08T25.65-56l1.291.071q.018,0,.3.027a3.941,3.941,0,0,1,.407.053q.124.027.416.08a2.463,2.463,0,0,1,.5.142q.212.088.5.23a3.4,3.4,0,0,1,.548.345,4.2,4.2,0,0,1,.513.469,3.38,3.38,0,0,1,.274.327,5.942,5.942,0,0,1,.513,1.035,6.736,6.736,0,0,1,.469,1.787q.142,1.132.221,2.415t.1,2.008V-43.9a37.454,37.454,0,0,1-.318,5.13,7.059,7.059,0,0,1-.442,1.76,4.5,4.5,0,0,1-.566,1.088l-.248.3a4.2,4.2,0,0,1-.513.469,2.981,2.981,0,0,1-.548.336q-.283.133-.5.221a2.464,2.464,0,0,1-.5.142q-.292.053-.425.08t-.407.053q-.274.027-.292.027-4.44.336-11.092.336Q12.188-34,9.49-34.075t-3.547-.133l-.867-.071-.637-.071a8.4,8.4,0,0,1-.964-.177,5.436,5.436,0,0,1-.9-.372,3.538,3.538,0,0,1-1-.725A3.38,3.38,0,0,1,1.3-35.95a5.942,5.942,0,0,1-.513-1.035,6.736,6.736,0,0,1-.469-1.787Q.176-39.9.1-41.186T0-43.194v-3.114a37.454,37.454,0,0,1,.318-5.13A7.058,7.058,0,0,1,.759-53.2a4.5,4.5,0,0,1,.566-1.088l.248-.3a4.2,4.2,0,0,1,.513-.469,3.4,3.4,0,0,1,.548-.345q.283-.142.5-.23a2.464,2.464,0,0,1,.5-.142q.292-.053.416-.08a3.941,3.941,0,0,1,.407-.053q.283-.027.3-.027Q9.2-56.25,15.85-56.25ZM31.7-33.783"
                      />
                    </g>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </Container>
      </footer>
    );
  }
}

export default Footer;
