import React from 'react';
import PropTypes from 'prop-types';

import './styles/Container.css';

class Container extends React.Component {
  static propTypes = {
    className: PropTypes.string,
  };

  render() {
    const { className, children } = this.props;

    return (
      <div className={`Container ${className ? className : ''}`}>
        {children}
      </div>
    );
  }
}

export default Container;
