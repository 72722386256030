import React from 'react';
import PropTypes from 'prop-types';

import './styles/Section.css';
import './styles/Grid.css';

class Section extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
  };

  render() {
    const { id, className, children } = this.props;

    return (
      <section id={id} className={className}>
        {children}
      </section>
    );
  }
}

export default Section;
