import React from 'react';
import PropTypes from 'prop-types';
import SlickSlider from 'react-slick';
import Container from './Container';
import Slide from './Slide';
import SlideNav from './SlideNav';
import Title from './Title';

class SimpleSlider extends React.Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
  }

  next = event => {
    const { target } = event;
    const slideNumber = target.dataset.index;
    this.slider.slickGoTo(slideNumber);
  };

  static propTypes = {
    slides: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  render() {
    const slides = [...this.props.slides];

    const settings = {
      className: 'center',
      infinite: true,
      speed: 500,
      centerMode: true,
      centerPadding: '20%',
      mobileFirst: false,
      responsive: [
        {
          breakpoint: 851,
          centerMode: false,
          settings: {
            centerMode: false,
            initialSlide: 1,
          },
        },
      ],
    };

    return (
      <Container className="Container--low-padding Container--slider">
        <SlickSlider ref={c => (this.slider = c)} {...settings}>
          {slides.map((slide, index) => (
            <Slide
              img={slide.img}
              title={slide.title}
              buttonLink={slide.buttonLink}
              index={index + 1}
            >
              {slide.content}
            </Slide>
          ))}
        </SlickSlider>

        <div className="Slider-nav">
          {slides.map((slide, index) => (
            <>
              {0 === index ? (
                <Title className="Title--2">
                  Chapter 1: The Enduring Legacy of Racism
                </Title>
              ) : (
                ''
              )}
              {7 === index ? (
                <Title className="Title--2">
                  Chapter 2: Barriers to Opportunity
                </Title>
              ) : (
                ''
              )}
              {12 === index ? (
                <Title className="Title--2">
                  Chapter 3: Building a Shared Future
                </Title>
              ) : (
                ''
              )}
              <div data-nav={index + 1} onClick={this.next}>
                <SlideNav img={slide.img} title={slide.title} index={index + 1}>
                  {slide.content}
                </SlideNav>
              </div>
            </>
          ))}
        </div>
      </Container>
    );
  }
}

export default SimpleSlider;
