import React from 'react';
import Scroll from 'react-scroll-to-element';
import Scrollspy from 'react-scrollspy';
import './styles/Header.css';

const links = [
  {
    section: 'Landing',
    title: 'E Pluribus Unum',
  },
  {
    section: 'Mission',
    title: 'Our Mission',
  },
  {
    section: 'Video',
    title: 'Divided By Design',
  },
  {
    section: 'Been',
    title: 'Where We’ve Been',
  },
  {
    section: 'Learned',
    title: 'What We’ve Learned',
  },
  {
    section: 'Plan',
    title: 'Insights Into Action',
  },
  {
    section: 'Conclusion',
    title: 'Conclusion',
  },
];

const sections = [...links].map(link => [link.section, link.title]);

const locations = [...sections].map(section => section[0]);

class Header extends React.Component {
  render() {
    return (
      <div className="Nav">
        <Scrollspy items={locations} offset={-80} currentClassName="is-current">
          {sections.map(section => {
            const [a, b] = section;
            return (
              <li key={a}>
                <Scroll key={a} type="id" element={a}>
                  <span>{b}</span>
                </Scroll>
              </li>
            );
          })}
        </Scrollspy>
      </div>
    );
  }
}

export default Header;
