import React from 'react';
import MapSVG from './MapSVG';
import './styles/Map.css';

class Map extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div className="Map__wrapper">
        <div className="Map">
          <figure>
            <MapSVG />
          </figure>
        </div>
      </div>
    );
  }
}

export default Map;
