import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Title from '../components/Title';
import Section from '../components/Section';
import Container from '../components/Container';
import Card from '../components/Card';
import Video from '../components/Video';
import Map from '../components/Map';
import SimpleSlider from '../components/SimpleSlider';
import '../components/styles/Button.css';
import videoLandingmp4 from '../video/landing/landing-vid.mp4';
import report from '../report/report.pdf';
import insight1 from '../images/insights/1-insights.jpg';
import insight2 from '../images/insights/2-insights.jpg';
import insight3 from '../images/insights/3-insights.jpg';
import insight4 from '../images/insights/4-insights.jpg';
import insight5 from '../images/insights/5-insights.jpg';
import insight6 from '../images/insights/6-insights.jpg';
import insight7 from '../images/insights/7-insights-2.jpg';
import insight8 from '../images/insights/8-insights.jpg';
import insight9 from '../images/insights/9-insights-2.jpg';
import insight10 from '../images/insights/10-insights.jpg';
import insight11 from '../images/insights/11-insights-2.jpg';
import insight12 from '../images/insights/12-insights.jpg';
import insight13 from '../images/insights/13-insights.jpg';
import insight14 from '../images/insights/14-insights.jpg';
import insight15 from '../images/insights/15-insights-2.jpg';

const griditems = [
  {
    img: insight1,
    title: `The Scale of Racism in America`,
    buttonLink: 'insights/1',
  },
  {
    img: insight2,
    title: 'Education and Inequality Go Hand in Hand',
    buttonLink: 'insights/2',
  },
  {
    img: insight3,
    title: 'The Legacy of Intentional Segregation',
    buttonLink: 'insights/3',
  },
  {
    img: insight4,
    title: 'Contemporary Impacts of Confederacy and Jim Crow',
    buttonLink: 'insights/4',
  },
  {
    img: insight5,
    title: 'Setting a Permissive Tone for Racism',
    buttonLink: 'insights/5',
  },
  {
    img: insight6,
    title: 'Control of One’s Personal Narrative',
    buttonLink: 'insights/6',
  },
  {
    img: insight7,
    title: 'Conversations about Racism Rarely Lead to Action',
    buttonLink: 'insights/7',
  },
  {
    img: insight8,
    title: 'Reinforced Racial and Economic Divisions',
    buttonLink: 'insights/8',
  },
  {
    img: insight9,
    title: 'The “Who-You-Know” Economy',
    buttonLink: 'insights/9',
  },
  {
    img: insight10,
    title: 'Systems that Perpetuate Poverty',
    buttonLink: 'insights/10',
  },
  {
    img: insight11,
    title: 'The Effects of Generational Inequities',
    buttonLink: 'insights/11',
  },
  {
    img: insight12,
    title: 'Inclusion Comes at a Cost',
    buttonLink: 'insights/12',
  },
  {
    img: insight13,
    title: 'Exposure to Different Cultures Promotes Acceptance',
    buttonLink: 'insights/13',
  },
  {
    img: insight14,
    title: 'Optimism Grows When Leaders Prioritize Inclusion',
    buttonLink: 'insights/14',
  },
  {
    img: insight15,
    title: 'People Connect around Cultural & Sporting Events',
    buttonLink: 'insights/15',
  },
];

const IndexPage = props => (
  <Layout className="pageWrapper">
    <SEO
      title="Divided by Design | E Pluribus Unum"
      description="Incubated by Emerson Collective and led by former New Orleans Mayor Mitch Landrieu, E Pluribus Unum is an initiative created to fulfill America’s promise of justice and opportunity for all by breaking down the barriers that divide us by race and class."
      keywords={[`e pluribus unum`]}
    />
    <Section id="Landing" className="Wrap Full__height-sec">
      <Img
        fluid={props.data.theLogo.childImageSharp.fluid}
        className="Logo Logo__top"
        alt="E Pluribus Unum"
      />
      <Container className="Full__height-container Container--no-padding">
        <Title el="h1" className="Title--0 Title--e Title--line-2">
          Divided By Design
        </Title>

        <Title className="Title--0-alt Title--e">
          Findings From The American South
        </Title>
      </Container>
      <div className="Scroll-down">
        <span>Scroll to Learn More</span>
      </div>
      <video className="Landing__video" autoPlay loop muted>
        <source src={videoLandingmp4} type="video/mp4" />
      </video>
    </Section>

    <Section id="Mission" className="Wrap Wrap--cropped ">
      <Container className="Container--mission">
        <Title el="h2" className="Title--1 Title--a Title--line-1">
          Our Mission
        </Title>
        <div className="fade fade-up">
          <p>
            Incubated at Emerson Collective and led by former New Orleans Mayor
            Mitch Landrieu, E Pluribus Unum is an initiative created to fulfill
            America’s promise of justice and opportunity for all by breaking
            down the barriers that divide us by race and class.
          </p>
        </div>
      </Container>
    </Section>

    <Section id="Video-title" className="Wrap Full__height-sec">
      <Container className="Full__height-container Container--no-padding">
        <Title el="h1" className="Title--0 Title--e Title--line-2">
          Out of Many, One
        </Title>
        <p className="fade fade-up">
          In the initiative’s first year, the E Pluribus Unum team traveled
          extensively across the American South to uncover and confront the
          challenges we face, to learn from people about what separates us and
          what can bring us together, and to find bold and effective solutions
          to tackle the modern legacy of Jim Crow so that an inclusive new South
          may be born.
        </p>
      </Container>
    </Section>

    <Section id="Video" className="Wrap Wrap--cropped">
      <Video
        videoTitle="E Pluribus Unum Fund: Branding Video"
        videoSrcURL="https://www.youtube.com/embed/6l9dyflpsJU"
      />
    </Section>

    <Section id="Been-title" className="Wrap Full__height-sec">
      <Container className="Full__height-container Container--no-padding Grid__item--center">
        <Title el="h1" className="Title--0 Title--e Title--line-2">
          Where We’ve Been
        </Title>
        <p className="fade fade-up">
          We met with people where they live, by traveling to 28 communities
          across 13 Southern states over the course of a year. We’ve visited
          diverse parts of the region, geographically, demographically, and
          culturally. Along our journey, we’ve had discussions in many different
          settings from one-on-one interviews to small groups in roundtables to
          focus groups to community listening sessions. The capstone of this
          research is a 1,800-person survey across the states we visited.
        </p>
      </Container>
    </Section>

    <Section id="Been" className="">
      <div className="map-info-wrap">
        <div className="map-info-box">
          <h5>Take a look at where we’ve been.</h5>
          <p>
            Click around on the interactive map to see what we learned at each
            location.
          </p>
        </div>
      </div>
      <Map />
    </Section>

    <Section id="Learned-title" className="Wrap Full__height-sec">
      <Container className="Full__height-container Container--no-padding Grid__item--center">
        <Title el="h1" className="Title--0 Title--e Title--line-2">
          What We’ve Learned
        </Title>
        <p className="fade fade-up">
          We’ve coded every word of what we’ve heard to help add context to our
          key takeaways. This data plus our existing knowledge has resulted in
          15 insights across three main categories. These insights are
          impactful, are supported by third-party data and can be backed up with
          context from real-life conversations we’ve had along our travels.
        </p>
      </Container>
    </Section>

    <Section id="Learned" className="">
      <SimpleSlider slides={griditems} />
    </Section>

    <Section id="Plan-title" className="Wrap Full__height-sec">
      <Container className="Full__height-container Container--no-padding Grid__item--center">
        <Title el="h1" className="Title--0 Title--e Title--line-2">
          Putting Insights <br /> into Action
        </Title>
      </Container>
    </Section>

    <Section id="Plan">
      <Container className="Texture-top-spacing">
        <Card
          img={
            <Img
              fluid={props.data.theMeeting.childImageSharp.fluid}
              className="Card__img fade"
              alt="Illustration depicting a presentation"
            />
          }
          title="Cultivate Courageous Leadership"
          href="https://www.unumfund.org/"
        >
          <p>
            We talked to and learned from residents, including elected leaders,
            who are deeply committed to strengthening their communities and are
            working in creative ways to address issues of race and class. Our
            research has taught us that people place a great deal of hope in
            their local political leadership, particularly young leaders who
            openly prioritize diversity and building more inclusive systems. We
            also heard how leadership has the potential to set a permissive tone
            for perpetuating the harms of racism. Entrenched distrust and
            disappointment in leadership bred by persistent racial disparities
            and perceived lack of transparency can have a deeply harmful effect
            on residents’ hope for their community’s future. Our future work
            will ensure Southern leaders are empowered to act on issues of race
            and class in new ways.
          </p>
        </Card>
        <Card
          img={
            <Img
              fluid={props.data.thePanel.childImageSharp.fluid}
              className="Card__img fade"
              alt="Illustration depicting an orator at a podium"
            />
          }
          title="Champion Transformative Policies"
          href="https://www.unumfund.org/"
        >
          <p>
            The vestiges of America’s Jim Crow era are vivid to those who
            continue to experience them through unequal access to opportunity,
            democracy, safety, and protection under the law. The issues we seek
            to change have plagued our communities since our country’s founding.
            We do not claim to be the first to take an interest in breaking
            these barriers; rather, we are committed to supplementing the deep
            knowledge in this field with insights shared with us by communities
            across the South. We also hope to accelerate reform by building
            greater public awareness and influencing action around
            transformative policy and political change at the local, state and
            federal level.
          </p>
        </Card>
        <Card
          img={
            <Img
              fluid={props.data.theSteps.childImageSharp.fluid}
              className="Card__img fade"
              alt="Illustration depicting a person helping another up a flight of stairs"
            />
          }
          title="Change Narratives"
          href="https://www.unumfund.org/"
        >
          <p>
            Too many people in our country lack an understanding of the scale of
            racism present in America, including our racial history and how it
            still permeates today’s institutions. Racism is often narrowly
            defined as overt individual actions rather than systemic injustices.
            This is because race is not easy to discuss openly. But without
            doing so, scholars argue that racism will still persist. We must
            teach the lasting impact racism has had on our institutions and
            persistently advance intentional efforts toward creating racial
            equity. Our work will focus on empowering storytelling that
            highlights the impacts of racial injustice in our institutions to
            provide fuller context needed so that positive change happen,
            holding the media accountable when it broadcasts implicit racial
            bias in its reporting that furthers racial stereotypes, and on
            ensuring that our full and accurate racial history is told and not
            whitewashed because it is inconvenient or too difficult to
            acknowledge.
          </p>
        </Card>
      </Container>
    </Section>

    <Section id="Conclusion" className="Wrap Full__height-sec">
      <Container className="Full__height-container Container--no-padding Grid__item--center">
        <Title el="h1" className="Title--0 Title--e Title--line-2">
          Conclusion
        </Title>
        <p className="fade fade-up">
          While our initial travels are complete, our journey continues. Moving
          forward, the E Pluribus Unum team will both create and amplify
          creative solutions for finding common ground by cultivating courageous
          leadership, changing narratives that perpetuate systemic and
          interpersonal racism, and championing transformative policy change,
          ultimately proving the American motto that ‘out of many, one’ ⁠— and
          we are better for it.
        </p>
        <a
          className="Button Button--arrow-down"
          rel="noopener noreferrer"
          target="_blank"
          href={report}
        >
          Download Full Report
        </a>
      </Container>
    </Section>
  </Layout>
);

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    theLogo: file(relativePath: { eq: "logo.png" }) {
      ...fluidImage
    }
    theSteps: file(relativePath: { eq: "report-steps.png" }) {
      ...fluidImage
    }
    thePanel: file(relativePath: { eq: "report-panel.png" }) {
      ...fluidImage
    }
    theMeeting: file(relativePath: { eq: "report-meeting.png" }) {
      ...fluidImage
    }
  }
`;

export default IndexPage;
