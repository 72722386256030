import React from 'react';
import PropTypes from 'prop-types';

import Title from './Title';

import './styles/Card.css';

class Card extends React.Component {
  static propTypes = {
    img: PropTypes.element.isRequired,
    title: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  };

  render() {
    const { img, title, href, children } = this.props;

    return (
      <div className="Card">
        {img}
        <div className="Card__text">
          <Title el="h3" className="Title--1-alt Title--c fade">
            {title}
          </Title>
          <div className="fade fade-up">
            {children}
            <a
              className="Card__link"
              href={href}
              rel="noopener noreferrer"
              target="_blank"
            >
              Learn More
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Card;
