/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Footer from './Footer';
import Header from './Header';

import './styles/Layout.css';

const Layout = ({ children, errorPath }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        footerLogo: file(relativePath: { eq: "foot-logo.png" }) {
          childImageSharp {
            fixed(width: 155) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <>
        {!errorPath ? <Header /> : ''}

        {children}
        <Footer footerLogo={data.footerLogo} />
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
