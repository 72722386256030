import React from 'react';
import { Link } from 'gatsby-plugin-modal-routing';
import PropTypes from 'prop-types';

import Title from './Title';

import './styles/Slide.css';

class Slide extends React.Component {
  static propTypes = {
    img: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    buttonLink: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
  };

  render() {
    const { img, title, buttonLink, index, children } = this.props;
    const slideCounter = 10 > index ? `0${index}` : index;

    return (
      <div className="Slide" data-slide-counter={slideCounter}>
        <div style={{ backgroundImage: `url(${img})` }}>
          <div className="Slide__content">
            <Title el="h3" className="Title--1 Title--b Slide__title">
              {title}
            </Title>
            <div
              className="Slide__text"
              dangerouslySetInnerHTML={{ __html: children }}
            />
            <Link
              className="Button Slide__link"
              to={`/modals/${buttonLink}`}
              asModal
            >
              Dive Deeper
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Slide;
