import Waypoint from 'waypoints-mrd';

export function mapPoints() {
  const spots = {
    ben: {
      title: 'Bentonville, AR',
      link: 'bentonville',
      id: 'ben',
    },
    bir: {
      title: 'Birmingham, AL',
      link: 'birmingham',
      id: 'bir',
    },
    cha: {
      title: 'Charlotte, NC',
      link: 'charlotte',
      id: 'cha',
    },
    chr: {
      title: 'Charleston, SC',
      link: 'charleston',
      id: 'chr',
    },
    col: {
      title: 'Columbus, GA',
      link: 'columbus',
      id: 'col',
    },
    hou: {
      title: 'Houston, TX',
      link: 'houston',
      id: 'hou',
    },
    jac: {
      title: 'Jackson, MS',
      link: 'jackson',
      id: 'jac',
    },
    kno: {
      title: 'Knoxville, TN',
      link: 'knoxville',
      id: 'kno',
    },
    lou: {
      title: 'Louisville, KY',
      link: 'louisville',
      id: 'lou',
    },
    mia: {
      title: 'Miami, FL',
      link: 'miami',
      id: 'mia',
    },
    mon: {
      title: 'Montgomery, AL',
      link: 'montgomery',
      id: 'mon',
    },
    msd: {
      title: 'Mississippi Delta',
      link: 'msdelta',
      id: 'msd',
    },
    nol: {
      title: 'New Orleans, LA',
      link: 'nola',
      id: 'nol',
    },
    ric: {
      title: 'Richmond, VA',
      link: 'richmond',
      id: 'ric',
    },
    cApp: {
      title: 'Central Appalachia',
      link: 'centralAppalachia',
      id: 'cApp',
    },
  };

  return spots;
}

export function fadeIn() {
  var siteElms = document.querySelectorAll('.fade');
  for (const siteElm of siteElms) {
    new Waypoint({
      element: siteElm,
      handler: function(direction) {
        this.element.classList.add('fade-in');
      },
      offset: '80%',
    });
  }
}
