export default () => {
  const launchYear = 2019;
  const thisYear = new Date().getFullYear();
  const copyrightSymbol = `\u{00A9}`;
  const emDash = `\u{2014}`;

  return `${copyrightSymbol} ${
    thisYear > launchYear ? `${launchYear}${emDash}${thisYear}` : launchYear
  }`;
};
